.dp__search_result {
   @include clearfix;
  .dp-search__result {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
    color: $brand-color-2;
	&_tax-header{
	    @include clearfix;
		margin: 0 15px 0 1rem;
		padding: 0.25rem;
	    color: $brand-color-20;
	    background-color: $brand-color-21;
	    box-shadow: $row-shadow-color;
	    margin-bottom: 1rem;
	    	.tax-message{
	    		font-size: 0.9rem;
	    		padding-left: 0.25rem;
	    	}
    }
    &_footer {
      @include clearfix;
      background-color: $brand-color-4;
    }
    &_card {
      box-shadow: $row-shadow-color;
    }
    &_title {
      @include clearfix;
      padding-bottom: 2px;
      padding-top: $base-padding/6;
      margin-left: -$base-margin/6;
      border-bottom: 1px solid lighten($brand-color-5, 30);
    }
    &_heading {
      padding-top: $base-padding/6;
      padding-bottom: $base-padding/6;
      margin-left: -$base-margin/6;
      text-transform: capitalize;
    }
    &_row {
      margin-left: -$base-margin/6;
    }
  
	.flight-details{
		margin-right: -13px;
		.tab-content{
			margin-top: 0.5rem;
		}
		.result__flight_details_button {
			color: $gray-base;
		}
		.result__extra_details {
			.details-content{
				float:right;
			}
		}
		.result__hotel_details_button, 
		.result__extra_details_cancellation, 
		.result__extra_details_hotel-highlights{
			color: $brand-color-2;
		    text-transform: uppercase;
		}
		.search__detail_flight{
			color: $dark-gray;
		}
	   &__tab-content{
	   	&_error-container{
	   		.alert__message{
	   			margin-bottom:0;
	   		}
	   	}
	   }
	
	}

	.search__result_card{
		margin-bottom: $base-padding/6; 
	}    

  }

}
.dp-results {
  &__dp_name {
  	color: $brand-color-2;
    float: left;
    font-size: 1rem;
    font-family: $font-family-number;
    text-transform: uppercase;
  }
  &__dp_type,&__dp_duration {
    @include make-one-sixth;
    text-align: center;
    float: right;
    color: $brand-color-2;
    padding-top: $base-padding/6;
    padding-bottom: $base-padding/6;
  }
  &__dp_type {
    background-color: $brand-color-3;
  }
  &__products {
    @include make-five-sixth;
    padding-bottom: $base-padding/2;
    padding-left: 0;
    padding-right: 0;
    @include clearfix;
  }
  &__book {
    @include make-one-sixth;
    text-align: center;
    &_button {
      @include button-size($base-padding/6, 0, 10px, inherit, 0);
      @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 20));
      margin-right: -$base-margin/6;
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
    }
  }
  &__dp_details {
    &_button {
      @include button-size($base-padding/6, 0, 10px, inherit, 0);
      @include make-button($dark, $brand-color-7, $dark, darken($brand-color-7, 20));
      width: 100%;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &__price {
    margin-bottom: $base-margin/3;
    &_amount {
      font-size: $font-size-h3;
      font-family: $font-family-number;
      color: $brand-color-1;
    }
  }
  &__customize-package{
    @include make-five-sixth;
    &_button {
      @include make-button($brand-color-3, $brand-color-2, $brand-color-3, darken($brand-color-2, 20));
      @include button-size($base-padding/6, 0, 10px, inherit, 0);
      font-weight: 700;
      width: 23%;
      float: right;
      text-transform: uppercase;
	  text-align: center;
	  cursor: pointer;
	  margin-right:0.625rem;
      &:first-child { 
	      margin-right:0;
      }
    }
  }
}
.product {
  @include make-one-fifth;
  padding-left: 0;
   &-active{
    .product__container {
    color: $brand-color-2;
    border: 2px solid $brand-color-6;
    width: 100%;
    float: left;
    height: 100px;
    border-radius: 7px;
    &_icon {
      width: 100%;
      background-color: $brand-color-4;
      border-bottom: 2px solid  $brand-color-6;
      height: 70%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      text-align: center;
      padding-top: 1rem;
    }
    &_name {
      text-align: center;
      padding: 5px;
    }
  }
  }
  &__container {
    @include clearfix;
    color: $brand-color-5;
    border: 2px solid lighten($brand-color-5,10);
    width: 100%;
    float: left;
    height: 100px;
    border-radius: 7px;
    &_icon {
      width: 100%;
      background-color: $brand-color-4;
      border-bottom: 2px solid lighten($brand-color-5,10);
      height: 70%;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      text-align: center;
      padding-top: 1rem;
    }
    &_name {
      text-align: center;
      padding: 5px;
    }
  }

}
.dp__search_result .dp-search__result .transfer-search-result-container__content{
	width:100%;
	.search__result_header{
		@include result-row-header;
	}
	.search__result_row{
		position:relative;
	}
	.transfer-content__transfer-price_data {
		width:18%;
	}
}

.highlights{
	cursor: pointer;
	color: $brand-color-2;
}