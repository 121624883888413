@mixin result-row-header_private{
	text-transform:uppercase;
	color:$brand-color-2;
	border-bottom: 1px solid darken($brand-color-4,20);
	padding-top: $base-padding/5;
	padding-bottom: $base-padding/5;
	padding-left: $base-padding/5;
    padding-right: $base-padding/5;		
    margin-bottom: $base-margin/5;
    margin-left: -$base-margin/2;
    margin-right: -15px;
}

@mixin  result-row-header{ 
	@include result-row-header_private; 
}