.dp-sightseeing-option-row {
	@include clearfix;
	padding-top: $base-padding/2;
    &__body {
        @include clearfix;
        box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);
        border: 1px solid $gray-lighting;
        border-radius: 0.25rem;
        padding: .5rem;
        padding-right: 0;
        
        .option-select {
			padding-right: 1.125rem;
		}

        .option-container {
            &__option {
                &-detail {
                    @include text-overflow;
                    float: left;
                    width: 40%;
                 	&_binding-rate-applicable-flag {
						font-size: $font-size-number-small;
						color: $brand-color-2;
					}
					.description {
						&__sightseeing {
							border: none;
							&_name {
								font-weight: 600;
							}
						}
					}
                }

                &-status {
                    float: left;
                    width: 20%;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                    margin-top: 0.50rem;
                }

                &-price {
               	 	display: inline-block;
                    width: 20%;
                    &_currency {
                        color: $brand-color-5;
                        font-size: 0.75rem;
                    }

                    &_amount {
                        color: $brand-color-1;
                        font-family: $font-family-number;
                        font-size: 1.25rem;
                    }
                    
                    &_adhoc {
                    	float: left;
                    	margin-top: 0.3rem;
                    	width: 1.5rem;
                    }
                }
            }
        }
        &_change-option {
            @include clearfix;
            @include make-one-fifth;
            float: right;
            padding-left: $base-padding/3;
            padding-right: $base-padding/3;
            border-color: $row-book-border-color;

            .change-option {
                &__button {
                    @include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
                    @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 20));
                    text-transform: uppercase;
                    width: 100%;
                    font-weight: 700;
                }
            }
        }
        &_options {
        	padding-top: $base-padding/2;
        }
    }
}

.dp-sightseeig-option {
	padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}
