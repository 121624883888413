.passenger-info-modal {

  .modal-header {
    margin-left: -$base-margin/2;
    margin-right: -$base-margin/2;
    font-size: $font-size-h3;
    color: $brand-color-2;
    padding-top: 0;
    padding-bottom: $base-padding/3;
  }
  &_header {
    padding-left: $base-padding/2;
  }
  input[type="text"],
  select {
    width: 100%;
    padding: 0.5rem 1rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
  }
  input[type="text"] {
    background-color: $input-bgcolor;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
  }
  label {
    margin-bottom: 0.25rem;
    color: $sf-label-color;
    font-size: 0.875rem;
  }
}
